import { ChevronRightIcon } from "@heroicons/react/20/solid"
import Image from "next/image"
import Link from "next/link"
import React from "react"

import SiteLayout from "@components/layouts/site"

import { Cta, MainCta } from "../components/Cta"
import Features from "../components/Features"
import Hero from "../components/Hero"
import WhyChooseUs from "../components/WhyChooseUs"

const Title = () => {
  return (
    <h1
      className={
        "max-w-md mt-6 text-4xl font-bold text-left lg:text-[54px] leading-none   text-neutral-1000 sm:mt-10"
      }
    >
      <span className={"text-primary-600"}>Booking Software,</span> Customized for your Business
    </h1>
  )
}

const Description = () => {
  return (
    <p className={"text-left mt-6 text-lg leading-8 text-[#3E4C5B] max-w-md"}>
      All-in-one online reservation platform to{" "}
      <span className={"font-semibold"}>streamline and grow your business.</span>
    </p>
  )
}

const Buttons = () => {
  return (
    <>
      {/* <a
        href="#"
        className="w-full lg:w-auto rounded-xl bg-primary-600 py-3.5 px-6 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
        Try for Free
      </a> */}
      <a
        href={"https://calendly.com/anne-pratt/smartrez-booking-software-demo"}
        className={
          "w-full lg:w-auto rounded-xl bg-primary-600 py-3.5 px-6 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        }
      >
        Book a Demo
      </a>
    </>
  )
}

const HeroImg = () => {
  return (
    <Image
      src={"/site/hero/hero.png"}
      className={"rounded-t-lg"}
      quality={100}
      alt={""}
      width={810}
      height={516}
    />
  )
}

const integrations = [
  {
    id: 1,
    icon: "/site/integrations/mailchimp-polygon.png",
  },
  {
    id: 2,
    icon: "/site/integrations/square-polygon.png",
  },
  {
    id: 3,
    icon: "/site/integrations/stripe-polygon.png",
  },
  {
    id: 4,
    icon: "/site/integrations/drive-polygon.png",
  },
  {
    id: 5,
    icon: "/site/integrations/drupal-polygon.png",
  },
  {
    id: 6,
    icon: "/site/integrations/joomla-polygon.png",
  },
]

const Index = () => {
  return (
    <SiteLayout>
      <div>
        <Hero
          heroImg={<HeroImg />}
          buttons={<Buttons />}
          title={<Title />}
          description={<Description />}
        />
        {/* Feature 1 */}
        <div className={"px-6 py-12 mx-auto space-y-2 text-center realtive"}>
          <div className={"mx-auto max-w-7xl"}>
            <div
              className={"grid grid-cols-1 gap-4 py-6 space-y-4 place-items-center lg:grid-cols-2"}
            >
              <div>
                <Image
                  className={"relative"}
                  src={"/site/home/feature-1.png"}
                  alt={""}
                  width={658}
                  height={459}
                />
              </div>
              <div className={"max-w-lg space-y-4 text-start"}>
                <div className={"space-y-2 text-center lg:text-left"}>
                  <p className={"text-base font-bold tracking-widest uppercase text-primary-600"}>
                    Simplified and Smart Software
                  </p>
                  <h3 className={"text-3xl font-bold text-neutral-1000"}>Integrated Solutions</h3>
                </div>
                <div className={"space-y-5"}>
                  <p className={"text-neutral-700"}>
                    One sign-in, everything in one place. Simple!
                  </p>
                  <div className={"flex items-start space-x-4"}>
                    <Image
                      src={"/site/featured-items/done.png"}
                      className={"w-6 h-6"}
                      alt={""}
                      width={30}
                      height={30}
                    />
                    <div>
                      <h4 className={"text-base font-semibold text-neutral-900"}>Waivers</h4>
                      <p className={"text-[#3E4C5B] text-base pt-1"}>
                        Enjoy FREE built-in waivers when using SmartRez.
                      </p>
                    </div>
                  </div>
                  <div className={"flex items-start space-x-4"}>
                    <Image
                      src={"/site/featured-items/done.png"}
                      className={"w-6 h-6"}
                      alt={""}
                      width={30}
                      height={30}
                    />
                    <div>
                      <h4 className={"text-base font-semibold text-neutral-900"}>
                        Email and SMS Notifications
                      </h4>
                      <p className={"text-[#3E4C5B] text-base pt-1"}>
                        Send SMS and/or Email notifications to customers to complete necessary forms
                        or details.
                      </p>
                    </div>
                  </div>
                  <div className={"flex items-start space-x-4"}>
                    <Image
                      src={"/site/featured-items/done.png"}
                      className={"w-6 h-6"}
                      alt={""}
                      width={30}
                      height={30}
                    />
                    <div>
                      <h4 className={"text-base font-semibold text-neutral-900"}>
                        Dashboard Overview
                      </h4>
                      <p className={"text-[#3E4C5B] text-base pt-1"}>
                        Simple reservation overview, shows everything you need to see in one spot.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Feature 2 */}
        <div
          className={
            "px-6 py-12 mx-auto space-y-2 text-center bg-gradient-to-l from-blue-200/60 via-blue-200/30 to-beige-300/40"
          }
        >
          <div className={"mx-auto max-w-7xl"}>
            <div
              className={"grid grid-cols-1 gap-4 py-6 space-y-4 place-items-center lg:grid-cols-2"}
            >
              <div className={"max-w-lg space-y-3 text-start"}>
                <div className={"text-center lg:text-left"}>
                  <p className={"text-base font-bold tracking-widest uppercase text-primary-600"}>
                    Booking Software
                  </p>
                  <h3 className={"text-3xl font-bold text-neutral-1000"}>
                    Manage Booking With Confidence
                  </h3>
                </div>
                <div className={"space-y-5"}>
                  <div className={"flex items-start space-x-4"}>
                    <Image
                      src={"/site/featured-items/done.png"}
                      className={"w-6 h-6"}
                      alt={""}
                      width={30}
                      height={30}
                    />
                    <div>
                      <h4 className={"text-base font-semibold text-neutral-900"}>
                        Custom Website Booking Widgets
                      </h4>
                      <p className={"text-[#3E4C5B] text-base pt-1"}>
                        Accept bookings anywhere, anytime by embedding a booking widget on your
                        website that will streamline your customer’s booking process.
                      </p>
                    </div>
                  </div>
                  <div className={"flex items-start space-x-4"}>
                    <Image
                      src={"/site/featured-items/done.png"}
                      className={"w-6 h-6"}
                      alt={""}
                      width={30}
                      height={30}
                    />
                    <div>
                      <h4 className={"text-base font-semibold text-neutral-900"}>
                        Real-time Availability
                      </h4>
                      <p className={"text-[#3E4C5B] text-base pt-1"}>
                        Offer customers real-time availability with a responsive activity booking
                        platform that automatically shows customers open time slots.
                      </p>
                    </div>
                  </div>
                  <div className={"flex items-start space-x-4"}>
                    <Image
                      src={"/site/featured-items/done.png"}
                      className={"w-6 h-6"}
                      alt={""}
                      width={30}
                      height={30}
                    />
                    <div>
                      <h4 className={"text-base font-semibold text-neutral-900"}>Rescheduling</h4>
                      <p className={"text-[#3E4C5B] text-base pt-1"}>
                        Ensure your customers get notified easily to reschedule or rebook their
                        activity due to any unforeseen circumstances.
                      </p>
                    </div>
                  </div>
                  <div className={"pl-8 lg:pl-12"}>
                    <Link
                      href={"/explore/inventory-management"}
                      className={
                        "flex items-center space-x-1 text-base font-semibold text-primary-600"
                      }
                    >
                      <span>View Inventory Management</span>{" "}
                      <ChevronRightIcon className={"w-5 h-5"} />{" "}
                    </Link>
                  </div>
                </div>
              </div>
              <div className={"order-first lg:order-last"}>
                <Image src={"/site/home/feature-2.png"} alt={""} width={520} height={459} />
              </div>
            </div>
          </div>
        </div>

        {/* Feature 3 */}
        {/* <div className="px-6 py-12 mx-auto space-y-2 text-center realtive">
          <div className="mx-auto max-w-7xl">
            <div className="grid grid-cols-1 gap-4 py-6 space-y-4 place-items-center lg:grid-cols-2">
              <div>
                <Image className="relative" src="/site/home/feature-3.png" alt="" width={598} height={352} />
              </div>
              <div className="max-w-lg space-y-4 text-start">
                <div className="space-y-2 text-center lg:text-left">
                  <p className="text-base font-bold tracking-widest uppercase text-primary-600">Payments</p>
                  <h3 className="text-3xl font-bold text-neutral-1000">Secure and Faster Payments</h3>
                </div>
                <div className="space-y-5">
                  <div className="flex items-start space-x-4">
                    <Image src="/site/featured-items/done.png" className="w-6 h-6" alt="" width={30} height={30} />
                    <div>
                      <h4 className="text-base font-semibold text-neutral-900">
                        Online Payment Plans Offered on Your Website
                      </h4>
                      <p className="text-[#3E4C5B] text-base pt-1">
                        Our OnlineWaiverPro.com platform is built into SmartRez Booking. Enjoy FREE Waivers when using
                        through SmartRez.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <Image src="/site/featured-items/done.png" className="w-6 h-6" alt="" width={30} height={30} />
                    <div>
                      <h4 className="text-base font-semibold text-neutral-900">Flexible Payment Options</h4>
                      <p className="text-[#3E4C5B] text-base pt-1">
                        Instead of depending on the information your employees enter in a sheet, automatic time mapping
                        data relies on workers’ activity.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <Image src="/site/featured-items/done.png" className="w-6 h-6" alt="" width={30} height={30} />
                    <div>
                      <h4 className="text-base font-semibold text-neutral-900">Manage Customer Payments</h4>
                      <p className="text-[#3E4C5B] text-base pt-1">
                        Our automatic time mapper assigns work to projects automatically without bothering your
                        employees.
                      </p>
                    </div>
                  </div>
                  <div className="pl-8 lg:pl-12">
                    <a href="#" className="flex items-center space-x-1 text-base font-semibold text-primary-600">
                      <span>View Payments</span> <ChevronRightIcon className="w-5 h-5" />{' '}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <Cta />
        <Features />

        <WhyChooseUs />

        {/* Integrations */}
        {/* <div className="flex justify-center px-8 py-12 lg:py-24">
          <div className="mx-auto space-y-10 text-center max-w-7xl">
            <div className="space-y-3">
              <p className="text-base font-bold tracking-widest uppercase text-primary-600">Integration</p>
              <h3 className="text-3xl font-bold text-neutral-1000">Integrate With Your Favorite Tools</h3>
            </div>
            <div className="grid grid-cols-3 lg:grid-cols-6 gap-x-4">
              {integrations.map(item => (
                <Image key={item.id} src={item.icon} width={96} height={96} alt="" />
              ))}
            </div>
            <div className="pl-8 lg:pl-12">
              <a href="#" className="flex items-center justify-center space-x-1 text-base font-semibold text-primary-600">
                <span>View More</span> <ChevronRightIcon className="w-5 h-5" />{' '}
              </a>
            </div>
          </div>
        </div> */}
        {/* CTA */}
        <MainCta />
      </div>
    </SiteLayout>
  )
}

export default Index
