import React, { FC } from "react"

interface HeroProps {
  heroImg?: any
  buttons?: any
  title: any
  description: any
}

const Hero: FC<HeroProps> = ({ heroImg, buttons, title, description, className = "" }) => {
  return (
    <div
      className={
        "relative bg-gradient-to-l from-[#73b3e98a] via-[#6db7ec4d] to-[#e9d9bb99] max-h-[776px] pb-12"
      }
    >
      <div
        className={`grid ${
          heroImg ? "lg:grid-cols-2" : "lg:grid-cols-1"
        } py-6 lg:place-items-center mx-auto max-w-7xl space-y-6 lg:px-8`}
      >
        <div className={"px-6 lg:px-3"}>
          <div className={"max-w-lg px-2 mx-auto lg:mx-0"}>
            {title}
            {description}
            <div
              className={
                "flex items-center justify-start w-full mt-10 space-y-3 text-center lg:flex-row lg:space-y-0 lg:space-x-4 "
              }
            >
              {buttons}
            </div>
          </div>
        </div>
        <div className={className}>{heroImg}</div>
      </div>
    </div>
  )
}

export default Hero
