import { ChevronRightIcon } from "@heroicons/react/20/solid"
import Image from "next/image"
import React from "react"

type FeatureCardProps = {
  title: string
  description: string
  img: string
  href: string
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, img, href }) => {
  return (
    <div className={"w-full space-y-3"}>
      <div className={"lg:mx-auto lg:w-[480px]"}>
        <div className={"rounded-lg lg:w-[480px]"}>
          <Image
            src={`/site/features/${img}.png`}
            alt={title}
            className={"w-auto py-4 mx-auto h-[300px]"}
            width={480}
            height={300}
            unoptimized
            quality={100}
          />
        </div>
        <div className={"max-w-xl px-6 py-2 space-y-2 text-start"}>
          <h4 className={"text-2xl font-bold text-neutral-1000"}>{title}</h4>
          <p className={"text-[#3E4C5B] font-regular text-lg"}>{description}</p>
          <a
            href={href}
            className={
              "flex items-center justify-start space-x-1 text-base font-semibold text-primary-600"
            }
          >
            <span>View More</span> <ChevronRightIcon className={"w-5 h-5"} />{" "}
          </a>
        </div>
      </div>
    </div>
  )
}

export default FeatureCard
