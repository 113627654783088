import Image from "next/image"
import Link from "next/link"
import React from "react"

export const Cta = () => {
  return (
    <div
      className={
        "relative px-4 py-16 mx-auto space-y-6 overflow-hidden text-center text-white bg-primary-1000"
      }
    >
      <div
        className={
          "absolute hidden lg:block rounded-full bg-gradient-to-l to-white/10 from-[#152E71] w-[400px] h-[400px] 2xl:w-[600px] 2xl:h-[600px] -left-32 top-20"
        }
      />
      <div
        className={
          "absolute hidden lg:block rounded-full bg-gradient-to-l from-white/10 to-[#152E71] w-[400px] h-[400px] 2xl:w-[600px] 2xl:h-[600px] -right-32 bottom-20"
        }
      />
      <h2 className={"text-3xl font-bold lg:text-4xl"}>
        Let's Work Together and Grow Your Business
      </h2>
      <p className={"text-lg font-normal"}>Do you have any Questions?</p>
      <div
        className={
          "flex flex-col justify-center px-4 pt-6 space-y-3 lg:space-x-3 lg:flex-row lg:space-y-0"
        }
      >
        {/* <Link href="/pricing"
          className="w-full px-4 py-3 text-white bg-transparent border border-white lg:w-auto rounded-xl">
          View Pricing
        </Link> */}
        <Link
          href={"/contact-us"}
          className={"w-full px-4 py-3 bg-white rounded-xl lg:w-auto text-primary-600"}
        >
          Contact Us
        </Link>
      </div>
    </div>
  )
}

export const CtaWithThumb = () => {
  return (
    <div className={"relative z-30 pt-60"}>
      <div className={"absolute inset-x-0 z-40 flex items-center justify-center top-16"}>
        <Image src={"/site/cta/cta-dashboard.png"} alt={""} width={650} height={350} />
      </div>
      <div
        className={
          "overflow-hidden relative space-y-6 pt-40 pb-28 text-white bg-[#131235] mx-auto text-center px-8"
        }
      >
        <div className={"absolute bottom-0 hidden rounded-full lg:block -left-0"}>
          <Image src={"/site/cta/mask-left.png"} alt={""} width={500} height={500} />
        </div>
        <div className={"absolute hidden rounded-full lg:block -right-0 -top-6"}>
          <Image src={"/site/cta/mask-right.png"} alt={""} width={600} height={600} />
        </div>
        <h2 className={"pt-6 text-4xl font-bold"}>Get an easy start with SmartRez now</h2>
        <p className={"text-lg font-normal"}>
          Start working with Online Booking that can provide everything you need to generate
          awareness, drive traffic, connect.
        </p>
        {process.env.NEXT_PUBLIC_HIDE_SIGNUP !== "true" && (
          <div className={"pt-6"}>
            <Link href={"/register"} className={"px-4 py-3 bg-white rounded-lg text-primary-600"}>
              Start free trial now
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export const MainCta = () => {
  return (
    <div className={"relative z-30"}>
      <div
        className={
          "overflow-hidden relative space-y-6 py-20 text-white bg-[#091840] mx-auto text-center px-6"
        }
      >
        <div className={"absolute bottom-0 rounded-full -left-0"}>
          <Image
            src={"/site/cta/cta-vector.png"}
            className={"opacity-5"}
            alt={""}
            width={1920}
            height={624}
          />
        </div>
        <div className={"relative z-50 pt-6 space-y-6"}>
          <h2 className={"text-4xl font-bold"}>Get an easy start with SmartRez now</h2>
          <p className={"text-lg font-normal"}>
            Discover how SmartRez can help you with solutions designed for your business.
          </p>
          {process.env.NEXT_PUBLIC_HIDE_SIGNUP !== "true" && (
            <div className={"py-6"}>
              <Link href={"/register"} className={"px-4 py-3 bg-white rounded-lg text-primary-600"}>
                Try for Free
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
